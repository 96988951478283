<template>
  <v-data-table
    class="grey lighten-3"
    :headers="headers"
    :items="debitPaymentsWithModified"
    hide-default-footer
    dense
    disable-sort
  >
    <template #[`item.payment_order.payment_doc_number`]="{item}">
      <span class="teal--text">{{ item.payment_order.payment_doc_number }}</span>
    </template>
    <template #[`item.sum`]="{item}">
      <span :class="{'red--text': item.sum < 0}"> {{ item.sum }}</span>
    </template>
    <template #[`item.actions`]="{item}">
      <v-menu offset-y>
        <template #activator="{ on, attrs }">
          <v-btn
            dark
            x-small
            v-bind="attrs"
            tabindex="-1"
            class="elevation-0"
            v-on="on"
          >
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            @click="remove(item.id)"
          >
            <v-list-item-title>Удалить</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>
<script>

import moment from "moment";
import {eventBus} from "@/bus";
import {UPDATE_DEBIT_PAYMENTS_TABLE} from "@/components/kdt/graph-b/events";

export default {
  props: {
    debitPayments: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      headers: [
        {
          text: "Вид платежа",
          value: "code"
        },
        {
          text: "Сумма платежа",
          value: "sum"
        },
        {
          text: "Номер ПП",
          value: "payment_order.payment_doc_number"
        },
        {
          text: "Дата ПП",
          value: "payment_date_pretty"
        },
        {
          text: "СП",
          value: "payment_type"
        },
        {
          text: "УНП (Личн.номер)",
          value: "owner"
        },
        {
          text: "",
          value: "actions"
        },
      ],
    }
  },
  computed: {
    debitPaymentsWithModified() {
      return this.debitPayments.map((payment) => {
        return {
          ...payment,
          payment_date_pretty: moment(payment.payment_order.payment_date).isValid()
            ? moment(payment.payment_order.payment_date).format("DD.MM.YYYY")
            : null,
          owner: payment.payment_order.unp || payment.payment_order.personal_number
        }
      })
    }
  },
  methods: {
    remove(id) {
      this.$store.dispatch("kdt/deleteDebitPayment", id).then(() => {
        eventBus.$emit(UPDATE_DEBIT_PAYMENTS_TABLE)
      }).catch(() => this.$error())
    },
  }
}
</script>
