<template>
  <div>
    <v-container
      fluid
    >
      <v-row>
        <v-col cols="12">
          <div
            class="statistics-box pa-4"
            :class="{
              'blocked-box': disabledView || canEdit
            }"
          >
            <v-row>
              <v-col
                cols="10"
                class="pb-0"
              >
                <div class="d-flex align-center mt-2">
                  <span class="font-weight-bold">
                    Графа B
                  </span>
                  <v-btn
                    width="32px"
                    height="32px"
                    min-width="32px"
                    class="ml-3 btn-edit"
                    style="z-index: 2"
                    @click="canEdit=!canEdit"
                  >
                    <v-icon> mdi-pencil </v-icon>
                  </v-btn>
                </div>
              </v-col>
              <v-col
                cols="2"
                class="d-flex justify-end"
              >
                <download-payments :debit-payments="debitPayments" />
              </v-col>
              <v-col cols="5">
                <declarations-payments :debit-payments="debitPayments" />
              </v-col>
              <v-col cols="7">
                <debit-payments-table :debit-payments="debitPayments" />
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import DeclarationsPayments from "@/components/kdt/graph-b/declarations-payments.vue";
import DebitPaymentsTable from "@/components/kdt/graph-b/debit-payments-table.vue";
import {eventBus} from "@/bus";
import {UPDATE_DEBIT_PAYMENTS_TABLE} from "@/components/kdt/graph-b/events";
import DownloadPayments from "@/components/kdt/graph-b/download-payments.vue";


export default {
  components: {DownloadPayments, DebitPaymentsTable, DeclarationsPayments},
  data: () => ({
    debitPayments: [],
    canEdit: true
  }),
  computed: {
    ...mapGetters({
      selected: "kdt/getSelected",
      user: "auth/getUser",
      disabledView: "kdt/getVisibility",
      requests: "kdt/getRequests"
    }),
  },
  watch: {
    "selected.id": {
      handler() {
        this.fetchPayments()
      }
    }
  },
  created() {
    eventBus.$on(UPDATE_DEBIT_PAYMENTS_TABLE, this.fetchPayments)
  },
  beforeDestroy() {
    eventBus.$off(UPDATE_DEBIT_PAYMENTS_TABLE, this.fetchPayments)
  },
  methods: {
    fetchPayments() {
      this.$store.dispatch('kdt/getDebitPaymentsList').then(({data}) => {
        const changeSum = data.map(payment => {
          if ([1,2].includes(payment.direction)) {
            return {
              ...payment,
              sum: payment.sum ? payment.sum * -1 : 0,
            }
          } else return payment
        });
        this.debitPayments = changeSum;
      })
    }
  }
};
</script>
